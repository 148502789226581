import React from "react"
import styles from "./index.module.css"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from "react-reveal"
import { getCurrency } from "../../utils"

export default ({ currency }) => {
  const data = useStaticQuery(graphql`
    query refermoreearmore {
      uk: file(relativePath: { eq: "Referral Rewards.webp" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      au: file(relativePath: { eq: "Referral Rewards Graphic AU.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      className={`container flex justify-between items-center lg:mt-24 mx-auto ${styles.main}`}
    >
      <div className={"w-full"}>
        <div className={`${styles.content}`}>
          <Fade cascade>
            <div className={"flex-1 my-auto"}>
              <Img
                loading={"eager"}
                className={"ml-3 lg:mt-0 mt-16 mx-4 my-8"}
                fluid={data[currency].childImageSharp.fluid}
              />
            </div>
            <div className={" flex-col flex-1 mx-8 justify-between"}>
              <h2 className={"heading mb-4"}>
                Referral rewards<span className={"red"}>.</span>
              </h2>
              <div className={"mb-4"}>
                <p className={"body-text"}>
                  With Referment you earn referral rewards when the person you
                  refer completes a first-round interview with the hiring
                  manager for the role.
                </p>
              </div>
              <div className={"mb-4"}>
                <p className={"body-text"}>
                  Once that’s happened you’ll be able to choose from a wide
                  array of rewards including a PayPal transfer, vouchers from
                  Amazon, Apple and Selfridges etc. or even credit to use on
                  Uber or Uber Eats.
                </p>
              </div>
              <div className={"mb-4"}>
                <p className={"body-text"}>
                  The more successful referrals you make the more you’ll earn.
                  You’ll earn {getCurrency(currency, 250)} for your first referral and then earn an
                  additional {getCurrency(currency, 50)} per subsequent referral until you hit
                  Referminator status where you’re earning {getCurrency(currency, 500)} per successful
                  referral!
                </p>
              </div>
              <div className={"mb-4"}>
                <p className={"body-text"}>
                  You can track your referral status in your Referment profile
                  on our platform and we’ll automatically get in touch when
                  you’re due a reward.
                </p>
              </div>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://app.referment.com/"
                style={{ width: "fit-content" }}
                className={
                  "block btn-lg mb-10 lg:mb-0  ml-auto mr-auto lg:ml-0 mt-8"
                }
              >
                Earn Referral Rewards
              </a>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}
